import React from "react";
import "./courses.css";
import img_courses from "../../../img/img_courses.webp";
import { Link } from "react-scroll";
import img_courses_1 from "../../../img/img_courses_3.webp";
import img_courses_2 from "../../../img/img_courses_2.webp";
import img_courses_3 from "../../../img/img_courses_1.webp";
import img_courses_4 from "../../../img/img_courses_4.webp";
import HairExpert from "./hairExpert/HairExpert";
import CourseFeatures from "./courseFeatures/CourseFeatures";
import IntroducingTrainer from "./introducingTrainer/IntroducingTrainer";

const Courses = () => {
  const info_courses = [
    {
      img_courses_info: img_courses_1,
      text: "وهي رحلة ممتعة ومثيرة للتعلـــــم حيث ستمكن دورتنا المتميزة جميـــــع عشاق فن قص الشعر سواء كـــــــــان المستوى مبتدئ أو متوسط من تقديم خدمات عالية الجودة لعملائهـــــــم وتحقيق رغبتهم في تطويـــــــــــر مهاراتهم بطريقة احترافية.",
    },
    {
      img_courses_info: img_courses_2,
      text: "دورتنا هي فرصة ذهبية لتطويــــــر مهارات المشاركات وتحقيق إمكاناتهم الكاملة في فن قص الشعر، نحــــــن ملتزمون بتوفير تجربة تعليمية مثيرة وممتعة تمكنك من اكتشـــــــــاف موهبتك والتميز في مجال قـــص الشعر",
    },
    {
      img_courses_info: img_courses_3,
      text: "ستغطي الدورة جميع جوانب قص الشعر بدءًا من الأساسيات الضرورية وصولًا إلى أحدث التقنيات والاتجاهات في عالم الشعر.",
    },
  ];

  return (
    <div id="courses">
      <div className="courses">
        <div className="right">
          <h2>دوراتنـــــــا</h2>

          <p>
            دورتنا الأولى من نوعها في قطر" تقنيات وقواعد قص الشعر " لتعلم
            أحـــــــــدث التقنيات في هذا المجال والحصول على المهارات اللازمة
            لبدء عملك الخاص في مجال تصفيف وقص الشعر.
          </p>

          <a
            className="courses_a"
            href="https://api.whatsapp.com/send?phone=97477344222"
            target="_blank"
          >
            أكتشف الآن
          </a>
        </div>

        <div className="left">
          <img src={img_courses} alt="img_courses" />
        </div>
      </div>

      <div className="info_courses">
        {info_courses.map((item, i) => (
          <div className={`info_course info_course_${i + 1}`} key={i}>
            <img src={item.img_courses_info} alt={item.text} />

            <p>
              {item.text.split("\n").map((text, index) => (
                <span key={index}>
                  {text}
                  <br />
                  <br />
                </span>
              ))}
            </p>
          </div>
        ))}
      </div>

      <div className="vision_mission_info_course">
        <div className="right_info_course">
          <p>
            ستقام الدورة مع "خبيرة الشعر/ منى أسد"، حيث ستشارك خبراتها ومعرفتها
            مع المشاركين للتعرف على أحدث صيحات قص الشعر، وابتكار أسلوبهم الخاص
            في تصفيف الشعر، وسيتعلمون التقنيات العملية باستخدام أحدث الأدوات
            والمعدات المستخدمة في قص الشعر. دورتنا هي فرصة ذهبية لتطوير مهارات
            المشاركين وتحقيق إمكاناتهم الكاملة في فن قص الشعر، ملتزمون بتوفير
            تجربة تعليمية مثيرة وممتعة تمكنك من اكتشاف موهبتك والتميز في مجال قص
            الشعر`
          </p>
        </div>

        <div className="left_info_course">
          <img src={img_courses_4} alt="img_courses_4" />
        </div>
      </div>

      <HairExpert />
      <CourseFeatures />
      <IntroducingTrainer />
    </div>
  );
};

export default Courses;
