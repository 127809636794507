import React, { useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.webp";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isChecked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const links = [
    { to: "header", name: "الصفحة الرئيسية" },
    { to: "about", name: "من نحن" },
    { to: "courses", name: "دوراتُنــا" },
    { to: "contact", name: "تواصل معنا" },
  ];

  return (
    <>
      <nav>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />

        <Link
          to="header"
          spy={true}
          smooth={true}
          offset={-200}
          duration={500}
          className="logo"
        >
          <img src={logo} alt="logo" />
        </Link>

        <ul className="links" onClick={handleToggle}>
          {links.map((item, i) => (
            <li key={i}>
              <Link
                onClick={handleToggle}
                to={item.to}
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>

        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-200}
          duration={500}
          className="contact"
        >
          تواصل معنا
        </Link>

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>
    </>
  );
};

export default Navbar;
