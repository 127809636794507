import React from "react";
import "./hairExpert.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineAccessTimeFilled, MdDateRange } from "react-icons/md";
import expert_botton_1 from "../../../../img/expert_botton_1.webp";
import expert_botton_2 from "../../../../img/expert_botton_2.webp";
import expert_botton_3 from "../../../../img/expert_botton_3.webp";
import expert_botton_4 from "../../../../img/expert_botton_4.webp";

const HairExpert = () => {
  const img_course = [
    {
      img: expert_botton_1,
      info_1: "معرفـة أسـرار قـص الشعـر و تقاسيـم الوجـه .",
      info_2: "تمرسي على خبايــا قصات الشعر و الزوايــا الصحيـه",
    },
    {
      img: expert_botton_2,
      info_1: "كوني خبيره بقبضة المقص لنتايج افضل و قصات شعـــر صحيــــــــه",
      info_2: "سلبيـات ومشاكــــل القـص ",
    },
    {
      img: expert_botton_3,
      info_1: "طـرق قـص الشعـر المعالج بالفرد- المنعمات والكيرلي والخشــــــن.",
      info_2: "الاستشـــــــوار و انواعــــه",
    },
    {
      img: expert_botton_4,
      info_1: "اكتشاف أسباب توقف نمو الشعر بعد القـص ",
      info_2: "تطبيق عملي لجميع المشاركات عن طريق المنكان ",
    },
  ];

  return (
    <div id="hair_expert">
      <div className="hair_expert">
        <p className="p1">يسعدنا أن نعلن عن دوراتنا التدريبية</p>
        <p className="p2">“تقنيات وقواعد قص الشعر”</p>
        <p className="p3">مع خبيرة الشعر/مــــنى أســــد</p>

        <div className="hair_expert_plan">
          <div>
            <p>
              <FaMapMarkerAlt />
              المكـــــان
            </p>
            <p>قاعــة فندقيــة تحـــدد لاحقـــاً</p>
          </div>
          <div>
            <p>
              <MdDateRange />
              تاريخ الدورة
            </p>
            <p>19 فبراير حتى 21 فبرايــــــــر</p>
          </div>
          <div>
            <p>
              <MdOutlineAccessTimeFilled />
              مدة الدورة
            </p>
            <p>3أيـــــــــــــــام 4 ساعـات لكـل يـوم </p>
          </div>
        </div>
      </div>
      <div className="hair_expert_botton">
        <h2 className="title">محــاور الــدورة</h2>
        <div className="all_expert_botton">
          {img_course.map((item, i) => (
            <div className="expert_botton" key={i}>
              <img src={item.img} alt={item.info_1} />

              <ul>
                <li>{item.info_1}</li>
                <li>{item.info_2}</li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HairExpert;
