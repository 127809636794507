import React from "react";
import "./introducingTrainer.css";
import introducing_trainer from "../../../../img/introducing_trainer.webp";

const IntroducingTrainer = () => {
  return (
    <div className="introducing_trainer">
      <h2 className="title">التعريف بالمدربة</h2>

      <div className="introducing_trainer_bottom">
        <p className="p_1">خبــيرة الشعـــــــــــر</p>
        <p className="p_2">منــــــــى أســـد</p>
        <div className="trainer_bottom">
          <div className="left">
            <h4>مدربـة عمانيـة خبيـرة في مجال الشعر و فـروة الـرأس </h4>
            <p>
              خبيرة صبغات وعلاجات شعر و معتمدة من المنظمة الدولية (الاتحاد
              العالمي الأكاديمي للتزييـــــــــــن).
            </p>
            <br />
            <p>
              حاصلة على شهادات عالمية في المجال من (لبنان- البرازيل-السعودية -
              تركيا - الإمارات).
            </p>

            <p>
              قدمت العديد من الورش والدورات المحلية والاقليمية للعديد من
              المتدربين من المؤسسات الحكومية و الأفراد من مختلف دول العالم
            </p>
          </div>
          <div className="right">
            <img
              src={introducing_trainer}
              alt="introducing_trainer"
              className="img_introducing_trainer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroducingTrainer;
