import React from "react";
import "./courseFeatures.css";

const CourseFeatures = () => {
  const data_course_features = [
    {
      text_1: "تكون المتدربه على علم بقوانين القص وزوايـــا الرأس وقص الشعر.",
      text_2: "تمنـــــح هدايـــــا لجميــــــع المشاركـــــات",
    },
    {
      text_1:
        "تتعلم المتدربه على كيفية قبضة المقص والمشط لتبدع في القص وتكتشــف قصــات بستايــلات جديــدة",
      text_2: "",
    },
    {
      text_1: "شهادة مقدمة من خبيرة الشعر منى أســـــد.",
      text_2: "توجد مترجمة باللغة الانجليزية للمتدربات الأجانــــــــب.",
    },
    {
      text_1: "متابعة من المدربه لمدة شهر على الواتســـــاب",
      text_2: "تمنح المتدربة شهادة معتمدة من أكاديمية يافي للتدريب",
    },
  ];

  return (
    <>
      <div className="course_features">
        <h2 className="title">محــاور الــدورة</h2>
        <div className="course_features_top">
          {data_course_features.map((item, i) => (
            <ul className="data" key={i}>
              <li>{item.text_1}</li>
              {item.text_2 !== "" ? <li>{item.text_2}</li> : ""}
            </ul>
          ))}
        </div>
      </div>
      <div className="course_features features_botton">
        <h2 className="title">أسعار الدورة</h2>
        <div className="course_features_botton">
          <div className="data">
            <div>
              <p>
                2000 <span>ريال</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <a
        className="book_now"
        href="https://api.whatsapp.com/send?phone=97477344222"
        target="_blank"
      >
        إحجز الآن
      </a>
    </>
  );
};

export default CourseFeatures;
