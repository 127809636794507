import React from "react";
import "./footer.css";
import { Link } from "react-scroll";
import logo from "../../../img/logo.webp";
import { NavLink } from "react-router-dom";
import { MdOutlinePhoneAndroid } from "react-icons/md";

const Footer = () => {
  const links = [
    { to: "header", name: "الصفحة الرئيسيـة" },
    { to: "about", name: "مـن نحــــــــــــن" },
    { to: "courses", name: "دوراتُنـــــــــــــــا" },
    { to: "contact", name: "تواصل معنــــــــا" },
  ];

  return (
    <div id="contact">
      <div className="contact">
        <div className="all_contact">
          <div className="right">
            <ul className="links">
              {links.map((item, i) => (
                <li key={i}>
                  <Link
                    to={item.to}
                    spy={true}
                    smooth={true}
                    offset={-200}
                    duration={500}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="center">
            <h2>تواصل معنـا</h2>

            <a
              className="whatsapp"
              href="https://api.whatsapp.com/send?phone=97477344222"
              target="_blank"
            >
              <MdOutlinePhoneAndroid />
              +974 7734 4222
            </a>
          </div>

          <div className="left">
            <NavLink to="/" className="logo">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
