import React from "react";
import "./header.css";
import img_header from "../../../img/header.webp";

const Header = () => {
  return (
    <div id="header">
      <div className="header">
        <div className="right">
          <h2>تقنيــات و قواعــد</h2>
          <h2>قــص الشعـــــــر</h2>
          <h3>لأول مرة فـي قطــــــــــر</h3>
          <p>
            من الصفر إلى الإحترافية تعلمي معنا أحدث التقنيات قص الشعر مع خبيرة
            الشعر المختصة "منى أسد".
            <br />
            في رحلة ممتعة من التعلم والتطبيق العملي باستخدام أحدث الأدوات
            والمعدات لقص الشعر.
          </p>

          <a
            className="find_out_now"
            href="https://api.whatsapp.com/send?phone=97477344222"
            target="_blank"
          >
            إكتشف الآن
          </a>
        </div>
        <div className="left">
          <img src={img_header} alt="img_header" />
        </div>
      </div>
    </div>
  );
};

export default Header;
