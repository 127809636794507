import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Header from "./header/Header";
import Copyrights from "../../components/copyrights/Copyrights";
import About from "./about/About";
import Courses from "./courses/Courses";
import Footer from "./footer/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Courses />
      <Footer />
      <Copyrights />
    </>
  );
};

export default Home;
