import React from "react";
import "./visionMission.css";
import img_vision from "../../../../img/vision.webp";
import img_mission from "../../../../img/mission.webp";
import img_mission_2 from "../../../../img/mission_2.webp";
import vision_mission from "../../../../img/vision_mission.webp";

const VisionMission = () => {
  return (
    <div className="all_vision_mission">
      <img
        src={vision_mission}
        alt="vision_mission"
        className="img_vision_mission"
      />

      <div className="vision_mission">
        <div className="right">
          <h2>رؤيتنـــــا</h2>

          <p>
            تتمثل رؤية الأكاديمية أن تكون الوجهة الأولــــــى للتدريب في قطر
            والوطن العربي وتقديم برامج تدريبية متميزة تلبي جميــع الاحتياجات .
          </p>
        </div>

        <div className="left">
          <img src={img_vision} alt="img_vision" />
        </div>
      </div>
      <div className="vision_mission vision_mission_2">
        <div className="right">
          <h2>مهمتنـــا</h2>

          <p>
            تتجسد مهمة الأكاديمية في الارتقاء بمستوى المهارات المهنية
            والأكاديمية للأفراد، تزويد الأفراد بالمهارت اللازمة للنجاح في المجال
            المهني و تطوير الموارد البشرية لتحقيق أهدافها الاستراتيجية.
          </p>
        </div>

        <div className="left">
          <img src={img_mission} alt="img_mission" />
        </div>
      </div>
      <div className="vision_mission vision_mission_3">
        <div className="right">
          <p>
            إيمانا منا في المجال المهني قمنا بعمل عدة دورات تدريبية لتأهيل أصحاب
            المشاريع الخاصة لتمكينهم في بدء وتطوير مشاريعهم على أرض الواقع
            وتعزيز فرص ال وتعزيز فرص النجاح للمشروع.
          </p>

          <p>
            نعمل بإحترافية وبخبرة عملية مدروسة لتوفير برامج تدريبية متخصصة من
            خلال مدربين مؤهلين على أعلى مستوى وذوي خبرة كبيرة للمساهمة في رفع
            المهارات وتعزيز فرص التطوير للأفراد ليساهم في نمو وتقدم المجتمع بشكل
            أكبر.
          </p>
        </div>

        <div className="left">
          <img src={img_mission_2} alt="img_mission_2" />
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
