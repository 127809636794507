import React from "react";
import "./about.css";
import img_about from "../../../img/about.webp";
import VisionMission from "./visionMission/VisionMission";

const About = () => {
  return (
    <div id="about">
      <div className="about">
        <h2>من نحـــــن</h2>
        <div className="about_div">
          <div className="right">
            <h2>“عن أكاديميــــة</h2>
            <h2>يافــــــــــــــــــي”</h2>

            <p>
              “بعد أعوام من الخبرة الطويلة والعمل بإحترافية مميزة ، أنشأنا
              أكاديمية يافي للتدريب لتقديم عُصارة خبراتنا في مجال التدريب
              والتطوير المهنـــــي لمختلف المجالات“.
            </p>

            <a
              className="know_more"
              href="https://api.whatsapp.com/send?phone=97477344222"
              target="_blank"
            >
              لمعرفة المزيد
            </a>
          </div>

          <div className="left">
            <img src={img_about} alt="img_header" />
          </div>
        </div>

        <VisionMission />
      </div>
    </div>
  );
};

export default About;
